<div class="dialog-head">
  <h3 class="m-0">{{ title }}</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="respone(null)">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p class="mt-3 body" [innerHTML]="body" [title]="body"></p>
  <div class="row justify-content-end align-items-center gap-1">
    <button class="primary-btn" (click)="respone(true)" *ngIf="okButtonName">
      {{ okButtonName }}
    </button>
    <button class="secondary-btn" (click)="respone(false)" *ngIf="cancelButtonName">
      {{ cancelButtonName }}
    </button>
  </div>
</div>
