import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin } from 'rxjs'
import { ApiService } from 'src/app/core/services/http/api.service'
import { StatusTypeTranslation } from 'src/app/shared/models/other-module'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private readonly api: ApiService, private readonly http: HttpClient) {}

  getTranslationOverride(status: StatusTypeTranslation, translationStatus?: StatusTypeTranslation, ui?: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: `translation`,
      queryParams: {
        status: status,
        ...(translationStatus != null && translationStatus != undefined && { translationStatus }),
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }

  deleteTrigger(id: string, ui: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'delete',
      endpoint: `triggers/${id}`,
      queryParams: {
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }
  enableTranslation(ui: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'put',
      endpoint: `translation/enable`,
      queryParams: {
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }
  disableTranslation(ui: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'put',
      endpoint: `translation/disable`,
      queryParams: {
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }
  activeTranslation(body, keyPath: string, ui: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'put',
      endpoint: `translation/active/${keyPath}`,
      body,
      queryParams: {
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }
  inActiveTranslation(body, keyPath: string, ui: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'put',
      endpoint: `translation/inactive/${keyPath}`,
      body,
      queryParams: {
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }
  deleteTranslation(path: string, ui: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'delete',
      endpoint: `translation/${path}`,
      queryParams: {
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }
  saveTranslation(body, ui: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'put',
      endpoint: `translation`,
      body,
      queryParams: {
        ...(ui != null && ui != undefined && { ui })
      }
    })
  }
  getJson(languagePath: string) {
    return this.http.get(languagePath)
  }
}
