// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const productionMode = true
const apiBase = '' // * <- Set to empty string specifically because absolute paths are explicitly ignored by Angular code on HttpClientXsrfModule
export const subdomain = window.location.pathname.split('/')[1]
const embeddedUrl = `/${subdomain}#/${subdomain}/preview/`
const iframeUrl = `/${subdomain}`
const config = {
  pusherInstanceId: '',
  clientDomain: '',
  jitsuClientKey: '',
  integrationDomain: '',
  jitsuTrackingHost: '',
  superSetUrl: '',
  keycloakUrl: '',
  embeddedUrl: '',
  embeddedUrlAgent: `/${subdomain}/preview/`,
  iframeUrl: '',
  isSSOEnabled: false,
  styleUrl: '',
  unleashUrl: '',
  groupName: '',
  keycloakClientId: '',
  realm: ''
}

function getConfig() {
  const url = '/api/v2/company/config' // Replace with your API URL
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, false) // The third argument (false) makes the request synchronous
  // debugger
  console.log('window.', window.location.pathname.split('/'))
  xhr.setRequestHeader('subdomain', window.location.pathname.split('/')[1])
  try {
    xhr.send()
    if (xhr.status === 200) {
      const response = JSON.parse(xhr.responseText)
      config.unleashUrl = response.result.unleashUrl
      config.pusherInstanceId = response.result.pusherInstanceId
      config.clientDomain = response.result.clientDomain
      config.jitsuClientKey = response.result.jitsuClientKey
      config.superSetUrl = response.result.superSetUrl
      config.styleUrl = response.result?.styleUrl || ''
      config.integrationDomain = response.result.integrationDomain
      config.jitsuTrackingHost = response.result.jitsuTrackingHost
      config.keycloakUrl = response.result.keycloakUrl
      config.isSSOEnabled = response.result.isSSOEnabled || false
      config.groupName = response.result.groupName || ''
      config.keycloakClientId = response.result.keycloakAdminClientId || 'km-admin'
      config.realm = response.result.realmName || window.location.pathname.split('/')[1].toLowerCase()
    } else {
      // Request failed
      console.error('Request failed with status:', xhr.status)
    }
  } catch (e) {
    // Handle any exceptions
    console.error('environment:getConfig: unable to get config : ', e)
  }
}
getConfig()
console.debug('config', config)

export const environment = {
  production: productionMode,
  apiBase,
  embeddedUrl: config.clientDomain?.concat(embeddedUrl),
  embeddedUrlAgent: config.clientDomain?.concat(config.embeddedUrlAgent),
  iframeUrl: config.clientDomain?.concat(iframeUrl),
  subdomain: window.location.pathname.split('/')[1],
  keycloakBaseUrl: config.keycloakUrl,
  keycloakClientId: config.keycloakClientId,
  keycloakRealm: config.realm,
  unleashUrl: config.unleashUrl,
  unleashClientKey: 'km-admin-key',
  integrationDomain: config.integrationDomain,
  unleashAppName: 'default',
  unleashEnvironment: 'development',
  jitsuTrackingHost: config.jitsuTrackingHost,
  jitsuClientKey: config.jitsuClientKey,
  jitsuConfig: { jitsuClientKey: config.jitsuClientKey, jitsuTrackingHost: config.jitsuTrackingHost },
  supersetDomain: config.superSetUrl,
  isSSOEnabled: config.isSSOEnabled,
  groupName: config.groupName,
  styleUrl: config.styleUrl,
  sentryDsn: 'https://472c1247049f831f394ab6477d4eecf6@o4506902130786304.ingest.us.sentry.io/4506902150381568'
}
