import { TranslateService } from '@ngx-translate/core'
import _ from 'lodash'
export async function retrieveAndSetLanguage(
  langCode: string,
  translate: TranslateService,
  overrideValue,
  prefix = '../assets/i18n/',
  suffix = '.json'
) {
  if (langCode)
    import(`../../../assets/i18n/${langCode}${suffix}`).then(res => {
      const newValue = _.merge({}, res.default, overrideValue)
      translate.setTranslation(langCode, newValue)
    })
}
