import { ModuleType } from '../models/other-module'

export interface IService {
  serviceId: string
  serviceCode: string
  route: any[]
  displayName: string
  moduleType: ModuleType
  iconName: string
  name: string
  position: number
  id?: string
}

export const serviceCode = {
  AR: 'AR_45309S',
  FAQ: 'FA_39541S',
  PG: 'PG_27488S',
  DT: 'DT_29891S',
  LMS: 'LMS_29891S'
}

export const Services: IService[] = [
  {
    serviceCode: serviceCode.PG,
    serviceId: '',
    displayName: 'Picture Guide',
    name: 'Picture Guide',
    route: [
      { name: 'Create', url: '/picture-guide/choose-format' },
      { name: 'Manage Library', url: '/picture-guide/manage-library' }
    ],
    moduleType: ModuleType.PG,
    iconName: 'picture-guide',
    position: 0
  },
  {
    serviceCode: serviceCode.DT,
    serviceId: '',
    route: [
      { name: 'Create', url: '/decision-tree/choose-format' },
      { name: 'Manage Library', url: '/decision-tree/manage-library' }
    ],
    displayName: 'Decision Tree',
    name: 'Decision Tree',
    moduleType: ModuleType.DT,
    iconName: 'call-handling',
    position: 0
  },
  {
    serviceCode: serviceCode.FAQ,
    serviceId: '',
    route: [
      { name: 'Create', url: '/faq/choose-format' },
      { name: 'Manage Library', url: '/faq/manage-library' }
    ],
    displayName: 'FAQ',
    name: 'FAQ',
    moduleType: ModuleType.FAQ,
    iconName: 'faq',
    position: 0
  },
  {
    serviceCode: serviceCode.AR,
    serviceId: '',
    route: [
      { name: 'Create', url: '/article/choose-format' },
      { name: 'Manage Library', url: '/article/manage-library' }
    ],
    displayName: 'Articles',
    name: 'Articles',
    moduleType: ModuleType.Article,
    iconName: 'article',
    position: 0
  },
  {
    serviceCode: serviceCode.LMS,
    serviceId: '',
    route: [
      { name: 'Create', url: '/lms/choose-format' },
      { name: 'SIDEMENU.Assignment', url: '/lms/assignment' },
      { name: 'Manage Library', url: '/lms/manage-library' }
    ],
    displayName: 'Quiz',
    name: 'Lms',
    moduleType: ModuleType.LMS,
    iconName: 'article',
    position: 0
  }
]

export enum serviceType {
  AR = 'AR',
  FAQ = 'FAQ',
  PG = 'PG',
  DT = 'DT',
  LMS = 'LMS'
}

export const servicesName = {
  Articles: 'Articles',
  Lms: 'Quiz',
  FAQ: 'FAQ',
  'Decision Tree': 'Decision Tree',
  'Picture Guide': 'Picture Guide'
}

// export serviceEnum =  typeof serviceType
